<template>
  <div class="main">
    <Header />
    <AnimatedText />
    <Navigation />
    <BouncingDot />
  </div>
</template>


<script>
import Navigation from "../components/Navigation";
import Header from "../components/Header";
import BouncingDot from "../components/BouncingDot";
import AnimatedText from "../components/AnimatedText";
export default {
  name: 'Dashboard',
  components: {AnimatedText, BouncingDot, Header, Navigation},
};
</script>

<style lang="scss" >
.main {
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
