<template>
  <div class="bouncing-dot__wrapper">
    <a href="http://quest.str-ing.org" target="_blank" rel="noopener noreferrer" class="bouncing-dot">
      <span class="bouncing-dot--pulse"></span>
    </a>
  </div>
</template>

<script>
export default {
  name: "BouncingDot",
}
</script>

<style scoped>
.bouncing-dot__wrapper {
  position: absolute;
  animation: bounceX 20s linear infinite alternate;
  top: 0;
  left: 0;
}
.bouncing-dot {
  position: absolute;
  animation: bounceY 14s linear infinite alternate;
  top: 0;
  left: 0;
  user-select: none;
  -webkit-user-drag: none;
}
.bouncing-dot--pulse {
  position: absolute;
  background: #0038FF;
  width: 26px;
  height: 26px;
  border-radius: 100%;
  animation: pulse 2s infinite;
}
@keyframes bounceX {
  100% {
    transform: translateX(calc(100vw - 26px));
  }
}
@keyframes bounceY {
  100% {
    transform: translateY(calc(100vh - 26px));
  }
}
@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 56, 255, 0.7);
  }

  30% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 56, 255, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 56, 255, 0);
  }
}
</style>
