<template>
  <div
    class="animated-text"
  >
    <vue-typer
      v-if="startTypers"
      :text="formatText(animatedText)"
      :repeat="Infinity"
      :shuffle="false"
      initial-action="typing"
      :pre-type-delay="70"
      :type-delay="70"
      :pre-erase-delay="3000"
      :erase-delay="100"
      erase-style="clear"
      :erase-on-complete="false"
      caret-animation="blink"
    ></vue-typer>
  </div>
</template>

<script>
import { VueTyper } from 'vue-typer'

export default {
  name: "AnimatedText",
  components: {
    VueTyper
  },
  methods: {
    delay(ms) {
      new Promise(res => setTimeout(res, ms))
    },
    formatText(aniTexts) {
      let maxChars = 40;
      let breakedText = [];
      aniTexts.forEach(text => {
        let words = text.split(" ");
        let breaked = "";
        let currentCount = 0;
        words.forEach((word) => {
          currentCount += word.length;
          currentCount += 1;
          if (currentCount >= maxChars) {
            currentCount = word.length;
            breaked = `${breaked}\n${word} `;
          } else {
            breaked = `${breaked}${word} `;
          }
        });
        breakedText.push(breaked);
      });
      return breakedText;
    },
  },
  data: () => ({
    startTypers: false,
    animatedText: [
        "str-ing.org empowers you, as a collector to sharpen your intuition.",
        "Explore the art world through a lens of unique, sharp, and precise insights.",
        "Instantly sense an artist’s global presence and recognition.",
        "Build a legacy that resonates with your deepest values.",
    ],
  }),
  mounted() {
    setTimeout(() => {
      this.startTypers = true;
    }, 150);
    let key = 1;
    const that = this;
    let endPos = 0;
    let startPos = 0;
    let smallStartPos = 1;
    let smallEndPos = 6;
    setInterval(function() {
      const firstLetter = document.querySelector(`.animated-text .left .char:nth-child(1)`);
      const secondLetter = document.querySelector(`.animated-text .left .char:nth-child(2)`);

      // Check for specific text for custom styling
    

      // end
      if (firstLetter && secondLetter) {
        if (firstLetter.innerText == 'E' && secondLetter.innerText == 'l') {
          endPos = 54;
          startPos = 43;
        } else if (firstLetter.innerText == 'U' && secondLetter.innerText == 'n') {
          endPos = 0;
          startPos = 0;
        } else if (firstLetter.innerText == 'I' && secondLetter.innerText == 'm') {
          endPos = 0;
          startPos = 0;
        } else if (firstLetter.innerText == 'E' && secondLetter.innerText == 'x') {
          endPos = 0;
          startPos = 0;
        } else if (firstLetter.innerText == 'A' && secondLetter.innerText == 'r') {
          endPos = 38;
          startPos = 27;
        } else if (firstLetter.innerText == 'E' && secondLetter.innerText == 'n') {
          endPos = 52;
          startPos = 41;
        } else if (firstLetter.innerText == 'T' && secondLetter.innerText == 'r') {
          endPos = 28;
          startPos = 19;
        } else if (firstLetter.innerText == 's' && secondLetter.innerText == 't') {
          endPos = 12;
          startPos = 1;
        }
      }

      if (!document.querySelector(`.animated-text .left .char:nth-child(${key})`) && key >= endPos) {
        key = 1;
        void (async () => {
          await that.delay(3000);
        })();
      }

      if (firstLetter && secondLetter) {
        if (firstLetter && firstLetter.innerText == 'P' && secondLetter.innerText == 's') {
          if (key >= smallStartPos && key <= smallEndPos)
            document.querySelector(`.animated-text .left .char:nth-child(${key})`).style.fontSize  = "20px";
          key++;
        } else if (document.querySelector(`.animated-text .left .char:nth-child(${key})`) && key < endPos) {
          if (key >= startPos) {
            if (firstLetter && firstLetter.innerText == 'T' && secondLetter.innerText == 'r')
              document.querySelector(`.animated-text .left .char:nth-child(${key})`).style.color = "blue";
            else
              document.querySelector(`.animated-text .left .char:nth-child(${key})`).style.color = "#000";
          }
          key++;
        }
      }
    }, 70);
  }
}
</script>

<style scoped>
.animated-text {
  text-align: center;
  font-size: 20px;
}
</style>

<style>
.vue-typer {
  font-family: 'Akkurat';
  font-weight: 400;
  font-size: 1.5vw;
  line-height: 2.5vw;
  display: block;
  height: 3em;

}
.vue-typer .custom.char.typed {
  display: unset;
  color: #9D9D9D;
}

/* Media query for smartphones */
@media only screen and (max-width: 600px) {
  .vue-typer {
    font-size: 16px; /* Ensures 20px on smartphones */
    line-height: 1.5em; /* Adjust line-height accordingly */
  }
.main {
    height: 95vh !important; }
}

@media only screen and (max-width: 375px) {
  .vue-typer {
    font-size: 16px; /* Adjust font size for iPhone 14 mini */
    line-height: 1.4em; /* Adjust line-height for readability */
  }
  .main {
    height: 85vh !important; }
}
</style>
